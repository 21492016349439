import React, { useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilSettings,
  cilUser,
  cilAccountLogout,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/2.jpg'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState({})

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('userData'))
    setUser(data)
  }, [])
  const handleLogout = () => {
    localStorage.clear('userData')
    navigate('/')
  }
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
   
        <CDropdownItem href="/profile">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        {user.role == '1' ? (
          <CDropdownItem href="/setting">
            <CIcon icon={cilSettings} className="me-2" />
            Settings
          </CDropdownItem>
        ) : (
          ''
        )}
        <CDropdownDivider />
        <CDropdownItem onClick={() => handleLogout()}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
       
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
