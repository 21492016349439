import React from 'react'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import {  AppSidebar, AppHeader } from '../../components/index'

const Dashboard = () => {
  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div>
            <div className="dashboardPage__inner">
              <div className="dashboardPage__head">
                <h1 className="dashboardPage__title">Dashboard</h1>
              </div>
              <WidgetsDropdown />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
